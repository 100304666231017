import React from 'react';

import { LANG_US_EN } from '@/src/common/consts/lang';
import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';
import Layout from '@components/layout';
import Segment from '@components/segment';
import Seo from '@components/seo';
import HeroCTA from '@components/sections/heroCTA/heroCTA';
import BgCardsTextST from '@components/staticSections/bgCardsTextST/BgCardsTextST';
import LicensedPtSliderST from '@components/staticSections/LicensedPtSliderST/LicensedPtSliderST';
import OutcomeST from '@components/staticSections/OutcomeST/OutcomeST';
import GetStartedST from '@components/staticSections/GetStartedST/GetStartedST';
import TestimonialGridST from '@components/staticSections/TestimonialGridST/TestimonialGridST';
import FaqST from '@components/staticSections/FaqST/FaqST';
import CtaST from '@components/staticSections/CtaST/CtaST';
import SocialProofQuoteCardST from '@components/staticSections/SocialProofQuoteCardST/SocialProofQuoteCardST';

const LANG = LANG_US_EN;
const ONBOARDING_URL = 'https://app.adjust.com/fk29c7x?campaign=landingpage';

const IndexPage = () => (
  <Layout
    lang="us"
    headerWitdh="header"
    headerStyle="flex--space-between"
    showFooter
  >
    <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_US} />
    <Seo title="Partners | Joint Academy" language="en" />
    <HeroCTA
      dataTheme="ice-blue"
      idCta="herobutton"
      imagedata4
      dataSection="hero_CTA"
      title="Treat joint and back pain from home"
      titleClassName=""
      text="Download the Joint Academy app to your phone and get started with a personalized exercise program today."
      textClassName=""
      tagHeadline="Get a text to download the app"
      smsTag
    />
    <SocialProofQuoteCardST
      lang={LANG}
      dataTheme="white"
      extraMargin="margin-top--lg"
    />
    <GetStartedST
      lang={LANG}
      renderDownloadButton
      ctaButtonText="Get Started"
      ctaDataGoal="Onboarding Viewed"
      ctaHref={ONBOARDING_URL}
    />
    <BgCardsTextST dataTheme="light" lang={LANG} />
    <SocialProofFeedST lang={LANG} />
    <TestimonialGridST lang="us_en_v2" />
    <LicensedPtSliderST
      lang={LANG}
      downloaddataGoal="Onboarding Viewed"
      downloadhref={ONBOARDING_URL}
      downloadclassname="btn btn--fullwidth btn--md btn--primary btn--lgMob"
      downloadtext="Get started today"
      smallTopSpacing
    />

    <OutcomeST lang={LANG} />

    <FaqST lang={LANG} />
    <CtaST
      lang={LANG}
      ctaDataGoal="Calendly Viewed"
      ctaHref="https://calendly.com/d/k6dx-mdtf/joint-academy-physical-therapy"
      ctaButtonText="Schedule Call"
    />
  </Layout>
);

export default IndexPage;
